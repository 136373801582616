import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useDebounceCallback } from '@react-hook/debounce';
import { useTranslation } from "react-i18next";
import DOMPurify from 'dompurify';
import he from 'he';
import { Box, Button, Container, Skeleton, Stack, Typography, Tooltip } from '@mui/material';

import PrimaryButton from './../common/ui/qvshop/components/core/PrimaryButton';

import { notScrollable, flexColFullHeightScrollable, masonryFullWidthScrollable, p1, m1 } from "../common/ui/sx/common";

import SpecificationsBox from '../common/ui/qvshop/components/SpecificationsBox';
import SubarticleSection from './components/SubarticleSection';
import ArticleReservationSection from '../common/ui/qvshop/components/ArticleReservationSection';
import ExtraArticles from './components/ExtraArticles';
import { usePageLayoutConfigurator } from '../common/hooks/usePageLayoutConfigurator';
import Breadcrumbs from '../common/ui/qvshop/components/Breadcrumbs';
import QVContainer from '../common/ui/qvshop/components/QVContainer';
import MasonrySection from '../common/ui/qvshop/components/MasonrySection';
import MasonryImageItem from '../common/ui/qvshop/components/MasonryImageItem';
import BackButton from '../common/ui/qvshop/components/core/BackButton';
import SubarticlesMasonry from './components/SubarticlesMasonry';
import AvailabilityCalendar from '../qvshop/components/AvailabilityCalendar';

import { htmlDecode } from '../common/utils/HtmlDecode';
import { ShopDatesInterval } from '../common/utils/ShopDatesInterval';

import Debounce from '../common/utils/Debounce';
import PageNames from '../common/utils/PageNames';
import DateTimeFormatConfig from '../common/utils/DateTimeFormatConfig';

import { checkAvailabilityForAccessories, checkAvailabilityForProduct, checkAvailabilityForSimilars, downloadAttachment, getAccessories, getProduct, getSimilars, resetStatusToIdle, selectAccessories, selectIsLoading, selectIsLoadingAccessories, selectIsLoadingProductAvailability, selectIsLoadingSimilars, selectLoadProductFailed, selectProduct, selectSimilars, selectTriggerProductAvailability, updateProduct } from '../common/store/productSlice';
import { selectAllArticlesMustBeInTheSameInterval, selectSemesterSettings, selectWorkingHours } from '../common/store/settingsSlice';
import { selectCategory, selectEndDate, selectStartDate, setEndDate, setStartDate } from '../common/store/filtersSlice';

export default function ProductDetailsView(props) {
    usePageLayoutConfigurator(PageNames.PRODUCT_DETAILS);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { productId } = useParams();

    const workingHours = useSelector(selectWorkingHours);
    const semesterSettings = useSelector(selectSemesterSettings);

    const selectedCategory = useSelector(selectCategory);

    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);

    const allArticlesMustBeInASingleInterval = useSelector(selectAllArticlesMustBeInTheSameInterval);
    const product = useSelector(selectProduct);
    const loadingProduct = useSelector(selectIsLoading);
    const loadProductFailed = useSelector(selectLoadProductFailed);
    const images = product && (product.images.length > 0 || (product.image && product.image !== '_dummy.svg')) ? [product.image, ...product.images] : [];

    const triggerAvailability = useSelector(selectTriggerProductAvailability);
    const loadingProductAvailability = useSelector(selectIsLoadingProductAvailability);

    const similarArticles = useSelector(selectSimilars);
    const loadingSimilars = useSelector(selectIsLoadingSimilars);
    const optionalArticles = useSelector(selectAccessories);
    const loadingAccessories = useSelector(selectIsLoadingAccessories);

    useEffect(() => {
        if (loadProductFailed) {
            // ANDY - 30.03.2023 - We also have the error here if we want to present it shomehow in the future
            navigate("/items/", { replace: true });
            dispatch(resetStatusToIdle());
        }
    }, [dispatch, navigate, loadProductFailed]);

    useEffect(() => {
        if (!(/^\d+$/.test(productId))) {
            navigate("/items/", { replace: true });
            return;
        }

        if (startDate && endDate && ((!product) || product.product_id !== productId)) {
            // Check if the from - to dates are valid and regenerate them if not before loading the product
            const startDateAsDate = DateTimeFormatConfig.getDateFromStandardPickerDateFormat(startDate);
            const endDateAsDate = DateTimeFormatConfig.getDateFromStandardPickerDateFormat(endDate);

            if (!startDateAsDate.isValid() || !endDateAsDate.isValid() || endDateAsDate.isSameOrBefore(startDateAsDate)) {
                const calculatedDates = ShopDatesInterval.get(workingHours, semesterSettings, 0, 0, startDateAsDate.isValid() ? startDate : null, endDateAsDate.isValid() ? endDate : null);
                dispatch(setStartDate(calculatedDates.startDate));
                dispatch(setEndDate(calculatedDates.endDate));
                return;
            }
            dispatch(getProduct({ productId }));
            dispatch(getAccessories({ productId }));
            dispatch(getSimilars({ productId }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, navigate, product, productId, startDate, endDate]);

    const debouncedAvailabilityCheck = useDebounceCallback(
        (product, similarArticles, optionalArticles) => {
            if (product && !product.hasRecurrenceErrors && !product.hasFromToErrors) {
                dispatch(checkAvailabilityForProduct());
            }

            if (Object.values(similarArticles).length > 0) {
                dispatch(checkAvailabilityForSimilars());
            }

            if (Object.values(optionalArticles).length > 0) {
                dispatch(checkAvailabilityForAccessories());
            }
        },
        // delay in ms
        Debounce.DEBOUNCE_TIMEOUT
    );

    useEffect(() => {
        debouncedAvailabilityCheck(product, similarArticles, optionalArticles);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, debouncedAvailabilityCheck, triggerAvailability]);

    const handleProductQuantityChanged = (newValue) => {
        dispatch(updateProduct({ item: product, field: "quantity", value: newValue }));
    }

    const handleDownloadAttachment = () => {
        dispatch(downloadAttachment())
    }

    const hasSubarticlesVariants = product && product.isConfigurable && Object.values(product.subarticles).length > 0;


    return (
        <Container sx={{ ...flexColFullHeightScrollable, ...notScrollable, ...p1 }} component="main" maxWidth={false} disableGutters>
            <Stack sx={m1} direction="column" spacing={3} alignItems="flex-start">
                <Stack direction="row" spacing={2} alignItems="center">
                    <BackButton to={"/items/" + selectedCategory} />
                    <Box sx={{
                        display: { xs: 'none', sm: 'block' }
                    }}>
                        <Breadcrumbs />
                    </Box>
                </Stack>
                <Stack direction="column" spacing={1} alignItems="flex-start">
                    <Typography variant="h6" component="div" paddingTop={2}>
                        {product ? he.decode(product.name) : <Skeleton width="500px" />}
                    </Typography>
                    {(allArticlesMustBeInASingleInterval || (product && !product.isConfigurable)) && 
                        (product && product.show_as_showcase ? 
                            <QVContainer borderRadius={8} sx={{ width: '500px', display: 'flex', justifyContent: 'center' }}>
                                <Tooltip 
                                    title={t("product.not_bookable_tooltip")}
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: 'white',
                                                color: 'black',
                                                padding: '10px',
                                                boxShadow: '0px 2px 4px rgba(0,0,0,0.2)'
                                            }
                                        }
                                    }}
                                    disableHoverListener={false}
                                    disableFocusListener={false}
                                    disableTouchListener={false}
                                >
                                    <span>
                                        <PrimaryButton label={t("product.not_bookable_in_shop")} disabled={true} />
                                    </span>
                                </Tooltip>
                            </QVContainer> :
                            <ArticleReservationSection loadingAvailability={loadingProductAvailability} item={product} onChangeQuantity={handleProductQuantityChanged} showQuantity={!(allArticlesMustBeInASingleInterval && product && product.isConfigurable)} 
                                showAddToCartButton={!(allArticlesMustBeInASingleInterval && product && product.isConfigurable)} />
                        )}

                    {product ? <Typography variant="body1" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(htmlDecode(product.description)) }} ></Typography> : <Typography variant="body1" color="text.secondary"><Skeleton /></Typography>}
                    {product && product.attachment && <Button variant="outlined" size="small" onClick={handleDownloadAttachment}>
                        {t("button.downloadAttachment")}
                    </Button>}
                </Stack>

                {!hasSubarticlesVariants &&
                    <Box>
                        <SpecificationsBox item={product} specifications={product && product.attributes ? product.attributes : []} />
                    </Box>
                }

                {images && images.length > 0 && 
                    <QVContainer sx={masonryFullWidthScrollable}>
                        <MasonrySection breakpointCols={{
                            default: 4,
                            600: 1,
                            800: 2,
                            1000: 3,
                            1200: 4,
                        }}
                            items={images}
                            card={MasonryImageItem} />
                    </QVContainer>}

                {!(product && product.show_as_showcase) && <AvailabilityCalendar />}

                {(product && product.show_as_showcase ?
                    <></> :
                    <>
                    {(!allArticlesMustBeInASingleInterval) && hasSubarticlesVariants &&
                        <Typography gutterBottom variant="h6" component="div">
                            {t("productdetails.subarticles_variants")}
                        </Typography>
                    }

                    {(!allArticlesMustBeInASingleInterval) && hasSubarticlesVariants && Object.values(product.subarticles).map(subarticle => 
                        <SubarticleSection key={subarticle.id} loadingAvailability={loadingProductAvailability} product={product} item={subarticle} />
                    )}
                    </>
                )}

                {(allArticlesMustBeInASingleInterval && product && product.isConfigurable) && Object.values(product.subarticles) && 
                    (product && product.show_as_showcase ? 
                        <></> :
                        <SubarticlesMasonry title={t("productdetails.subarticles_variants")} loadingAvailability={loadingProductAvailability} product={product} loading={loadingProduct} />
                    )
                }

                {optionalArticles && Object.values(optionalArticles).length > 0 && <ExtraArticles title={t("optionalArticles")} articles={optionalArticles} loading={loadingAccessories} hideAddToCartButton={(!allArticlesMustBeInASingleInterval) && product && product.isConfigurable} />}
                {similarArticles && Object.values(similarArticles).length > 0 && <ExtraArticles title={t("similarArticles")} articles={similarArticles} loading={loadingSimilars} hideAddToCartButton={(!allArticlesMustBeInASingleInterval) && product && product.isConfigurable} />}
            </Stack>
        </Container>
    );
};
