import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import he from 'he';

import { Card, CardContent, CardMedia, Button, CardActionArea, CardActions, Typography, Stack, Skeleton, Tooltip } from '@mui/material';

import { flexSpaceBetween, alignItemsEnd } from "../../sx/common"

import ShopConfig from '../../../api/config/shopConfig';
import { AvailabilityUtils } from '../../../store/utils/AvailabilityUtils';

import { selectPricesEnabled } from '../../../store/settingsSlice';
import { selectFromToHasErrors, selectRecurrenceHasErrors, selectRecurring, selectStartDate, selectEndDate, } from '../../../store/filtersSlice';
import { addItemToCart } from '../../../store/cartSlice';
import { selectIsLoadingAvailability } from '../../../store/productsSlice';

import { calculateArticlePrice } from '../../../utils/ArticlePrice';
import { ProductUtils } from '../../../store/utils/ProductUtils';

export default function ProductCard(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { item, hideAddToCartButton } = props;

    const loadingAvailability = useSelector(selectIsLoadingAvailability);
    const itemAvailability = item.available;
    const { available, addToCartButtonLabel } = AvailabilityUtils.handleAvailabilityResponse(itemAvailability);
    
    const hasRecurrenceErrors = useSelector(selectRecurrenceHasErrors);
    const hasFromToErrors = useSelector(selectFromToHasErrors);
    const recurring = useSelector(selectRecurring);

    const showAddToCartButton = (!loadingAvailability) && (!hideAddToCartButton) && !item.isConfigurable;
    const disableAddToCartButton = (!!(!recurring && hasFromToErrors) || !!(recurring && hasRecurrenceErrors) || loadingAvailability || !available);

    const startDateState = useSelector(selectStartDate);
    const endDateState = useSelector(selectEndDate);

    const pricesEnabled = useSelector(selectPricesEnabled);

    const handleAddToCart = () => {
        dispatch(addItemToCart(item));
    }

    const buildSpecifications = (spec, index) => {
        return (
            <Stack key={index+"_"+spec.attribute+"_"+spec.value} direction="row">
                <Stack direction="row" width="50%">
                    <Typography variant="body2" color="text.secondary">
                        {spec.attribute}
                    </Typography>
                </Stack>
                <Stack direction="row" width="50%">
                    <Typography variant="body2" color="text.secondary">
                        {spec.value}
                    </Typography>
                </Stack>
            </Stack>
        )
    };

    const showPrice = pricesEnabled && !recurring && !loadingAvailability && !item.isConfigurable;

    let articlePrice = 0;
    let articleInfoColor = "";

    if(showPrice) {
        articlePrice = calculateArticlePrice(item.article_price, item.price_unit, startDateState, endDateState);
        articleInfoColor = disableAddToCartButton ? "text.disabled" : "primary.main";
    }

    return (
        <Card variant="outlined" sx={{ width: "100%", minWidth: 280 }}>
            <CardActionArea component={RouterLink} to={"/itemdetails/"+item.product_id}>
                { item.image && item.image !== "_dummy.svg" && <CardMedia
                    component="img"
                    height="140"
                    src={ShopConfig.config().getURLForProductImage(item.image)}
                    alt={item.name}
                    sx={{ objectFit: "contain" }}
                /> }
                <CardContent>
                    <Stack direction="column" spacing={2}>
                        <Typography gutterBottom variant="h6" component="h6">
                            {he.decode(item.name)}
                        </Typography>
                        <Stack direction="column" spacing={0.5}>
                            { item.attributes.map(buildSpecifications)}
                        </Stack>
                    </Stack>
                </CardContent>
            </CardActionArea>
            <CardActions sx={{...flexSpaceBetween, ...alignItemsEnd}}>
                <Button size="small" variant="borderless" component={RouterLink} to={"/itemdetails/"+item.product_id}>
                    {t("button.details")}
                </Button>
                { loadingAvailability && <Skeleton variant="rectangular" width={130} height={20} />}
                { showAddToCartButton &&
                    <Stack direction="column" spacing={0} alignItems="end" >
                        {showPrice &&
                        <Typography sx={{pl: 1, pr: 1, fontWeight: 'bold'}} color={articleInfoColor}>{ProductUtils.formatPrice(articlePrice)} €</Typography>
                        }
                        
                        {(item.show_as_showcase ?
                            <Tooltip 
                                title={t("product.not_bookable_tooltip")}
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            bgcolor: 'white',
                                            color: 'black',
                                            padding: '10px',
                                            boxShadow: '0px 2px 4px rgba(0,0,0,0.2)'
                                        }
                                    }
                                }}
                            >
                                <Button size="small" variant="borderless">
                                    {t("product.not_bookable_in_shop")}
                                </Button>
                            </Tooltip> :
                            <Button size="small" variant="borderless" disabled={disableAddToCartButton} onClick={handleAddToCart} >
                                {t(loadingAvailability ? "button.addToCartLoadingAvailability" : addToCartButtonLabel)}
                            </Button> 
                        )}
                    </Stack>
                }

                { item.isConfigurable && !loadingAvailability &&
                    (item.show_as_showcase ? 
                        <Tooltip 
                            title={t("product.not_bookable_tooltip")}
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        bgcolor: 'white',
                                        color: 'black',
                                        padding: '10px',
                                        boxShadow: '0px 2px 4px rgba(0,0,0,0.2)'
                                    }
                                }
                            }}
                        >
                            <Button size="small" variant="borderless">
                                {t("product.not_bookable_in_shop")}
                            </Button>
                        </Tooltip> :
                        <Button size="small" variant="borderless" disabled={disableAddToCartButton} component={RouterLink} to={"/itemdetails/"+item.product_id} >
                            {t(loadingAvailability ? "button.addToCartLoadingAvailability" : (available ? "button.isConfigurable" : addToCartButtonLabel))}
                        </Button>
                    )
                }
                
            </CardActions>
        </Card>
    );
}
